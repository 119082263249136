<template>
    <div class="pages">
        <b-overlay :show="showLoader" variant="transperant" opacity="1" spinner-variant="primary">

            <b-card no-body>
                <b-card-header>
                    <b-card-title>Pages</b-card-title>
                    <b-button v-ripple.400="'rgba(186, 191, 199, 0.15)'" variant="primary" class="btn-icon ml-auto"
                        @click="addModalShow = true">
                        Add New Page
                        <feather-icon icon="PlusIcon" />
                    </b-button>
                </b-card-header>
                <div class="mb-2 mx-1">
                    <!-- Table Top -->
                    <b-row>
                        <!-- Per Page -->
                        <b-col cols="12" md="8" class="d-flex align-items-center justify-content-start mb-1 mb-md-0">
                            <label>Show</label>
                            <v-select v-model="perPage" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                                :options="pageOptions" :clearable="false" class="per-page-selector d-inline-block mx-50" />
                            <label>entries</label>
                        </b-col>
                        <!-- Search -->
                        <b-col cols="12" md="4">
                            <div class="d-flex align-items-center justify-content-end">
                                <b-form-input v-model="filter" class="d-inline-block mr-1" placeholder="Search..." />
                            </div>
                        </b-col>
                    </b-row>
                </div>
                <b-row>
                    <b-col cols="12">
                        <b-table hover responsive class="position-relative" :per-page="perPage" :current-page="currentPage"
                            :items="pages" :fields="fields" :sort-by.sync="sortBy" :sort-desc.sync="sortDesc"
                            :sort-direction="sortDirection" :filter="filter" show-empty
                            empty-text="لم يتم العثور على سجلات مطابقة" :filter-included-fields="filterOn"
                            @filtered="onFiltered">

                            <template #cell(title)="data">
                                <b-media vertical-align="center">
                                    <template #aside>
                                        <b-avatar size="32" :src="data.item.logo" variant="default" />
                                    </template>
                                    <b-link class="font-weight-bold d-block text-nowrap">
                                        {{ data.item.title }}
                                    </b-link>
                                    <small class="text-muted">@{{ data.item.username }}</small>
                                </b-media>
                            </template>

                            <template #cell(slug)="data">
                                <b-badge :href="LINKINBIO_BASE_URL + data.item.slug" variant="primary" target="_blank">
                                    <feather-icon icon="LinkIcon" class="mr-25" />
                                    <span>{{ data.item.slug }}</span>
                                </b-badge>
                            </template>

                            <template #cell(description)="data">
                                <p
                                    v-if="data.item.description != null && data.item.description != ''"
                                    v-html="data.item.description.length < 35 ? data.item.description : data.item.description.substring(0, 30) + '...'">
                                </p>
                            </template>

                            <template #cell(created_at)="data">
                                <b-badge variant="primary">
                                    <feather-icon icon="StarIcon" class="mr-25" />
                                    <span>{{ moment(data.item.created_at).format('llll') }}</span>
                                </b-badge>
                            </template>

                            <template #cell(action)="data">
                                <div class="float-right">
                                    <b-button v-ripple.400="'rgba(113, 102, 240, 0.15)'" variant="outline-danger"
                                        class="btn-icon mr-1" @click="deletePage(data.item.id)">
                                        <feather-icon icon="TrashIcon" />
                                    </b-button>
                                    <b-button v-ripple.400="'rgba(113, 102, 240, 0.15)'" variant="outline-secondary"
                                        class="btn-icon mr-1" :to="{ name: 'page-details', params: { id: data.item.id } }">
                                        <feather-icon icon="EditIcon" />
                                    </b-button>
                                    <b-button v-ripple.400="'rgba(113, 102, 240, 0.15)'" variant="outline-secondary"
                                        class="btn-icon" :href="LINKINBIO_BASE_URL + data.item.slug" target="_blank">
                                        <feather-icon icon="ExternalLinkIcon" />
                                    </b-button>
                                </div>
                            </template>
                        </b-table>
                    </b-col>
                </b-row>

                <b-row class="p-2">
                    <b-col cols="12">
                        <b-pagination v-model="currentPage" :total-rows="totalRows" :per-page="perPage"
                            class="mb-0 mt-1 mt-sm-0" pills first-number last-number prev-class="next-item"
                            next-class="prev-item" />
                    </b-col>
                </b-row>
            </b-card>
        </b-overlay>

        <b-modal id="modal-2" ref="modal-add" v-model="addModalShow" title="Create Page" ok-title="Create"
            cancel-title="Cancel" cancel-variant="outline-secondary" @hidden="resetAddModal" @ok="handleOkAddModal"
            no-close-on-backdrop size="xl" centered>
            <div>
                <b-row>
                    <b-col cols="12">
                        <div class="icon-search-wrapper mb-2 mt-1 mx-auto">
                            <b-form-group>
                                <b-input-group class="input-group-merge">
                                    <b-input-group-prepend is-text>
                                        <feather-icon icon="SearchIcon" />
                                    </b-input-group-prepend>
                                    <b-form-input id="icons-search" v-model="seachQuery" placeholder="search..." />
                                </b-input-group>
                            </b-form-group>
                        </div>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col cols="4" v-for="template in templates" :key="template.id">
                        <b-link class="mb-1" @click="page.template = template.id">
                            <b-overlay rounded :show="page.template == template.id" variant="dark" opacity="0.5">
                                <div class="border rounded p-1 ">
                                    <div class="d-flex align-items-center justify-content-between">
                                        <h4 class="text-primary text-center">{{ template.name }}</h4>
                                        <b-form-radio v-model="page.template" name="some-radios" :value="template.id" />
                                    </div>
                                    <Preview :slug="'templates/' + template.code" />
                                </div>
                                <template #overlay>
                                    <div class="text-center">
                                        <feather-icon size="50" class="text-white" icon="CheckIcon" />
                                    </div>
                                </template>
                            </b-overlay>
                        </b-link>
                    </b-col>
                </b-row>
            </div>
        </b-modal>
    </div>
</template>


<script>
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { ValidationProvider, ValidationObserver, localize } from 'vee-validate'
import { required } from '@validations'
import {
    BCard, BCardText, BRow, BCol, BButton, BAvatar, BLink, BBadge, BTabs, BTab, BMedia, BImg, BFormInput, BFormGroup, BForm,
    BOverlay, BFormCheckbox, BModal, BFormFile, BTable, BFormSelect, BPagination, BInputGroup,
    BInputGroupAppend, BCardTitle, BCardHeader, BSpinner, BMediaAside, BMediaBody, BFormRadio, BInputGroupPrepend
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import axios from 'axios'

import Preview from './components/Preview.vue'
export default {
    components: {
        Preview, ValidationProvider, ValidationObserver,
        BCard, BCardText, BRow, BCol, BButton, ToastificationContent,
        BLink, BAvatar, BBadge, BTabs, BTab, BMedia, BImg, BFormInput, BFormGroup, BForm, BOverlay,
        BModal, BFormFile, BTable, BFormSelect, BPagination, BInputGroup, BCardTitle, BCardHeader,
        BInputGroupAppend, BFormCheckbox, vSelect, BSpinner, BMediaAside, BMediaBody, BFormRadio, BInputGroupPrepend
    },
    directives: {
        Ripple,
    },
    data() {
        return {
            LINKINBIO_BASE_URL: process.env.VUE_APP_LINKINBIO_BASE_URL,
            seachQuery: '',
            overlayImage: false,
            addModalShow: false,
            loading: false,
            showLoader: true,
            perPage: 5,
            pageOptions: [5, 15, 20],
            totalRows: 1,
            currentPage: 1,
            sortBy: '',
            sortDesc: false,
            sortDirection: 'asc',
            filter: null,
            filterOn: [],
            fields: [
                { key: 'title', label: 'Page' },
                { key: 'slug', label: 'Link' },
                { key: 'description', label: 'description' },
                { key: 'created_at', label: 'created at' },
                { key: 'action', label: '' },
            ],
            pages: [],
            templates: [],
            timezones: [],
            page: {
                template: null
            },
            required
        }
    },
    mounted() {
        this.getPages()
        this.getTemplates()
    },
    methods: {
        handleOkAddModal(bvModalEvt) {
            this.loading = true
            // Prevent modal from closing
            bvModalEvt.preventDefault()
            // Trigger submit handler
            this.submitForm()
        },
        async submitForm() {
            await axios.post('/pages/', this.page)
                .then((response) => {
                    if (response.data.code == 201 || response.data.code == 200){
                        this.$toast({
                            component: ToastificationContent,
                            props: {
                                title: 'Page created',
                                icon: 'CheckIcon',
                                text: 'Page was created successfully.',
                                variant: 'success',
                            },
                        })
                        this.loading = false
                        this.addModalShow = false
                        this.resetAddModal()
                        this.$router.push({ name: 'page-details', params: { id: response.data.page.id } })
                        console.log(response);
                    } else {
                        this.$toast({
                            component: ToastificationContent,
                            props: {
                                title: 'Upgrade Plan!',
                                icon: 'AlertCircleIcon',
                                text: 'Social Profiles quota exceeded, please upgrade plan.',
                                variant: 'danger',
                            },
                        })
                    }
                })
                .catch((error) => {
                    this.loading = false
                    this.$toast({
                        component: ToastificationContent,
                        props: {
                            title: 'Warning !',
                            icon: 'AlertCircleIcon',
                            text: 'Something Went Wrong.',
                            variant: 'danger',
                        },
                    })
                    JSON.stringify(error);
                })
        },
        resetAddModal() {
            this.page.template = null
        },
        deletePage(id) {
            this.$swal({
                title: 'Are you sure?',
                text: "You won't be able to revert this!",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonText: 'Yes, delete it!',
                customClass: {
                    confirmButton: 'btn btn-primary',
                    cancelButton: 'btn btn-outline-danger ml-1',
                },
                buttonsStyling: false,
            }).then(result => {
                if (result.value) {
                    axios.delete(`/pages/${id}/`)
                        .then(response => {
                            console.log(response);
                            this.$swal({
                                icon: 'success',
                                title: 'Deleted!',
                                text: 'Your Page has been deleted.',
                                customClass: {
                                    confirmButton: 'btn btn-success',
                                },
                            })
                            this.getPages()
                        })
                        .catch(error => {
                            console.log(error);
                        })
                }
            })
        },
        async getPages() {
            await axios.get("/pages")
                .then(response => {
                    console.log(response);
                    this.pages = response.data
                    this.totalRows = response.data.length
                    setTimeout(() => { this.showLoader = false }, 500);
                })
                .catch(error => {
                    console.log(error);
                })
        },
        getTemplates() {
            axios.get("/pages/templates/")
                .then(response => {
                    console.log(response);
                    this.templates = response.data
                })
                .catch(error => {
                    console.log(error);
                })
        },
        onFiltered(filteredItems) {
            // Trigger pagination to update the number of buttons/pages due to filtering
            this.totalRows = filteredItems.length
            this.currentPage = 1
        },
    }
}
</script>
<style lang="scss">
@import '~@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/pages/ui-feather.scss';
</style>
<style>
.popular {
    border: 1px dashed var(--primary);
}
</style>